import React from "react";
import Registration from "../views/front_pages/auth/Register";
import Login from "../views/front_pages/auth/Login";
import ForgotPassword from "../views/front_pages/auth/ForgotPassword";
import UserView from "../views/app_pages/UserManagement/UserView";
import PageNotFound from "../views/errors/PageNotFound";
import Unauthorized from "../views/errors/Unauthorized";
import HomePage from "../views/front_pages/HomePage/HomePage";
import Profile from "../views/front_pages/Profile/Profile";
import ChangePassword from "../views/front_pages/Profile/ChangePassword";
import SavingGroup from "../views/front_pages/SavingGroup/SavingGroup";
import VerifyOtp from "../views/front_pages/auth/VerifyOtp";
import ChangePasswordWithOtp from "../views/front_pages/auth/ChangePasswordWithOtp";
import { Route, Switch } from "react-router-dom";
import LayoutView from "../views/layouts";
import { RouterConfigProps } from "../config/InterfacesAndTypes";
import VerifyAccount from "../views/front_pages/auth/VerifyAccount";
import CreateGroupRequest from "../views/front_pages/SavingGroup/CreateGroupRequest";
import GroupMemberDetails from "../views/front_pages/SavingGroup/GroupMemberDetails";
import GroupList from "../views/front_pages/SavingGroup/GroupList";
import GroupDetails from "../views/front_pages/SavingGroup/GroupDetails";
import MemberSetting from "../views/front_pages/SavingGroup/MemberSetting";
import EditGroup from "../views/front_pages/SavingGroup/EditGroup";
import JoinGroupRequestList from "../views/front_pages/SavingGroup/JoinGroupRequestList";
import GroupExpenseList from "../views/front_pages/GroupExpense/GroupExpenseList";
import MyGroupExpenseList from "../views/front_pages/GroupExpense/MyGroupExpenseList";
import AddExpense from "../views/front_pages/GroupExpense/AddExpense";
import AddMyExpense from "../views/front_pages/GroupExpense/AddMyExpense";
import LoansList from "../views/front_pages/Loans/LoansList";
import AddLoans from "../views/front_pages/Loans/AddLoans";
import Donation from "../views/front_pages/Donation";
import AddDonationComponent from "../views/front_pages/Donation/Donation/components/AddComponent";
import AddFines from "../views/front_pages/Fines/AddFines";
import FinesList from "../views/front_pages/Fines/FinesList";
import ViewGroupFines from "../views/front_pages/Fines/ViewGroupFines";
import MyWallet from "../views/front_pages/Wallet/MyWallet";
import WalletSettings from "../views/front_pages/Wallet/WalletSettings";
import AddMeetings from "../views/front_pages/Meetings/AddMeetings";
import EditMeetings from "../views/front_pages/Meetings/EditMeetings";
import ViewGroupLoans from "../views/front_pages/Loans/ViewGroupLoans";
import ChangePasswordWithLink from "../views/front_pages/auth/ChangePasswordWithLink";
import LoanInstallmentsList from "../views/front_pages/Loans/LoanInstallmentsList";
import GroupWalletView from "../views/front_pages/Wallet/GroupWalletView";
import MeetingRemark from "../views/front_pages/Meetings/MeetingRemark";
import MeetingsSettings from "../views/front_pages/Meetings/MeetingsSetting";
import Attendance from "../views/front_pages/Meetings/Attendance";
import Passbook from "../views/front_pages/Passbook/Passbook";
import MemberPassbook from "../views/front_pages/Passbook/MemberPassbook";
import MemberPassbookDetails from "../views/front_pages/Passbook/MemberPassbookDetails";
import Election from "../views/front_pages/Election/Election";
import AddElection from "../views/front_pages/Election/AddElection";
import ElectionDetails from "../views/front_pages/Election/ElectionDetails";
import AboutUs from "../views/front_pages/cms/AboutUs";
import TermsConditions from "../views/front_pages/cms/TermsConditions";
import Feedback from "../views/front_pages/cms/Feedback";
import ContactUs from "../views/front_pages/cms/ContactUs";
import AllReports from "../views/front_pages/AllReports/Reports";
import ElectionAddCandidates from "../views/front_pages/ElectionReferee/ElectionAddCandidates";
import ElectionRefereeResult from "../views/front_pages/ElectionReferee/ElectionRefereeResult";
import ElectionRefereeResultFeedback from "../views/front_pages/ElectionReferee/ElectionRefereeResultFeedback";
import VoteTermsConditions from "../views/front_pages/Vote/VoteTermsConditions";
import Vote from "../views/front_pages/Vote/Vote";
import VoteThankYou from "../views/front_pages/Vote/VoteThankYou";
import News from "../views/front_pages/News/News";
import NewsDetail from "../views/front_pages/News/NewsDetail";
import NewsDetailMobile from "../views/front_pages/News/MobileNewsDetail";
import ElectionResult from "../views/front_pages/Election/ElectionResult";
import AddDonationCampaignComponent from "../views/front_pages/Donation/DonationCampaign/components/AddComponent";
import EditDonationCampaignComponent from "../views/front_pages/Donation/DonationCampaign/components/EditComponent";
import GroupInvitation from "../views/front_pages/SavingGroup/GroupInvitation";
import NotificationList from "../views/layouts/components/NotificationList"
import { displayHeader } from "../config/Global";
// import CashEntries from "../views/front_pages/AddCashWallet/CashEntries";
// import AddCash from "../views/front_pages/AddCashWallet/AddCash";
import PrivacyPolicy from "../views/front_pages/cms/PrivacyPolicy";
import AssociatedGroups from "../views/front_pages/ElectionReferee/AssociatedGroups";
import WelfareRequestList from "../views/front_pages/WelfareRequest/WelfareRequestList";
import AddWelfareRequest from "../views/front_pages/WelfareRequest/AddWelfareRequest";
import MyWelfareRequestList from "../views/front_pages/WelfareRequest/MyWelfareRequestList";
import AddMyWelfareRequest from "../views/front_pages/WelfareRequest/AddMyWelfareRequest";
import PayDonationComponent from "../views/front_pages/Donation/Donation/PayDonationComponent";
import MeetingsList from "../views/front_pages/Meetings/MeetingsList";
import MeetingDetails from "../views/front_pages/Meetings/MeetingDetails";
import MyGroups from "../views/front_pages/SavingGroup/MyGroups";
import PayDonationCampaignComponent from "../views/front_pages/Donation/DonationCampaign/components/PayComponent";
import AddMoneyToWallet from "../views/front_pages/Wallet/AddMoneyToWallet";
import WithdrawMoneyFromWallet from "../views/front_pages/Wallet/WithdrawMoneyFromWallet";
import NotificationRedirect from "../views/layouts/components/NotificationRedirect";
import GeneratedOtp from "../views/front_pages/GeneratedOTP/GeneratedOtp";
import TransferFundsList from "../views/front_pages/TransferFunds/TransferFundsList";
import AddTransferFunds from "../views/front_pages/TransferFunds/AddTransferFunds";

const RouterConfig: RouterConfigProps[] = [
	{
		title: "Home Page",
		path: "/",
		component: <HomePage />,
		settings: ["Header", "Footer"],
		displayCategory: displayHeader.SLIDER.DISPLAY,
		displayType: displayHeader.SLIDER.TYPE.HOME,
	},
	{
		title: "Register Page",
		path: "/register",
		component: <Registration />,
		displayCategory: 'banner',
	},
	{
		title: "Login Page",
		path: "/login",
		component: <Login />,
	},
	{
		title: "Forgot Password",
		path: "/forgot-password",
		component: <ForgotPassword />,
	},
	{
		title: "Verify Account",
		path: "/verify-account",
		component: <VerifyAccount />,
	},
	{
		title: "Verify OTP",
		path: "/verify-otp",
		component: <VerifyOtp />,
	},
	{
		title: "Change Password With Otp",
		path: "/change-password-with-otp",
		component: <ChangePasswordWithOtp />,
	},
	{
		title: "Change Password With Link",
		path: "/reset/:otp",
		component: <ChangePasswordWithLink />,
	},
	{
		title: "Profile",
		path: "/my-profile",
		component: <Profile />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.PROFILE,
	},
	{
		title: "Profile Change Password",
		path: "/change-password",
		component: <ChangePassword />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.PROFILE,
	},
	{
		title: "Users Page",
		path: "/user-management",
		component: <UserView />,
		settings: ["AppHeader", "AppSidebar"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.USER_MANAGEMENT,
	},
	{
		title: "Create Saving Group",
		path: "/create-saving-group",
		component: <CreateGroupRequest />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "Saving Group",
		path: "/saving-group/details",
		component: <SavingGroup />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "My Groups",
		path: "/my-group/list",
		component: <MyGroups />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "Saving Group President",
		path: "/saving-group/member-setting",
		component: <MemberSetting />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "Edit Group",
		path: "/saving-group/edit-group",
		component: <EditGroup />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "Edit Group",
		path: "/group/:id/edit",
		component: <EditGroup />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "Group Member Details",
		path: "/saving-group/member-details",
		component: <GroupMemberDetails />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.GROUP_MEMBER,
	},
	{
		title: "Join Group Request List",
		path: "/saving-group/request-list",
		component: <JoinGroupRequestList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "Group List",
		path: "/group-list",
		component: <GroupList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "Group Details",
		path: "/group-list/:id/group-details",
		component: <GroupDetails />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "Group Invitation",
		path: "/group/:id/invitation",
		component: <GroupInvitation />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "Group Expenses",
		path: "/group-expenses",
		component: <GroupExpenseList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.GROUP_EXPANSE,
	},
	{
		title: "Add Expenses",
		path: "/group-expenses/add-expenses",
		component: <AddExpense />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.GROUP_EXPANSE,
	},
	{
		title: "My Group Expenses",
		path: "/my-group-expenses",
		component: <MyGroupExpenseList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.GROUP_EXPANSE,
	},
	{
		title: "Add My Expenses",
		path: "/my-group-expenses/add-my-expenses",
		component: <AddMyExpense />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.GROUP_EXPANSE,
	},
	{
		title: "Wallet",
		path: "/my-wallet",
		component: <MyWallet />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.WALLET,
	},
	{
		title: "Add money to wallet",
		path: "/my-wallet/add-money",
		component: <AddMoneyToWallet />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.WALLET,
	},
	{
		title: "Withdraw money from wallet",
		path: "/my-wallet/withdraw-money",
		component: <WithdrawMoneyFromWallet />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.WALLET,
	},
	{
		title: "View Group Wallet",
		path: "/view-group-wallet",
		component: <GroupWalletView />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.WALLET,
	},
	{
		title: "Wallet",
		path: "/wallet-settings",
		component: <WalletSettings />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.WALLET,
	},
	{
		title: "My Fines",
		path: "/my-fines",
		component: <FinesList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.FINES,
	},
	{
		title: "View Group Fines",
		path: "/view-group-fines",
		component: <ViewGroupFines />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.FINES,
	},
	{
		title: "Add Expenses",
		path: "/add-fines",
		component: <AddFines />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.GROUP_EXPANSE,
	},
	{
		title: "Loans",
		path: "/my-loans",
		component: <LoansList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.LOAN,
	},
	{
		title: "Loans",
		path: "/my-loan/:id/installments",
		component: <LoanInstallmentsList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.LOAN,
	},
	{
		title: "View Group Loans",
		path: "/view-group-loans/:id/installments",
		component: <LoanInstallmentsList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.LOAN,
	},
	{
		title: "View Group Loans",
		path: "/view-group-loans",

		component: <ViewGroupLoans />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.LOAN,
	},
	{
		title: "Loans",
		path: "/add-loan",
		component: <AddLoans />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.LOAN,
	},
	{
		title: "Donation",
		path: "/donation",
		component: <Donation />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.SLIDER.DISPLAY,
		displayType: displayHeader.SLIDER.TYPE.DONATION,
	},
	{
		title: "Add Donation",
		path: "/donation/add",
		component: <AddDonationComponent />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.SLIDER.DISPLAY,
		displayType: displayHeader.SLIDER.TYPE.DONATION,
	},
	{
		title: "Add Donation",
		path: "/donation/add/:id",
		component: <AddDonationComponent />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.SLIDER.DISPLAY,
		displayType: displayHeader.SLIDER.TYPE.DONATION,
	},
	{
		title: "Pay Donation",
		path: "/donation/:id/pay",
		component: <PayDonationComponent />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.SLIDER.DISPLAY,
		displayType: displayHeader.SLIDER.TYPE.DONATION,
	},
	{
		title: "Add Donation Campaign",
		path: "/donation-campaign/create",
		component: <AddDonationCampaignComponent />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.SLIDER.DISPLAY,
		displayType: displayHeader.SLIDER.TYPE.DONATION,
	},
	{
		title: "Edit Donation Campaign",
		path: "/donation-campaign/:id/edit",
		component: <EditDonationCampaignComponent />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.SLIDER.DISPLAY,
		displayType: displayHeader.SLIDER.TYPE.DONATION,
	},
	{
		title: "Pay Donation Campaign",
		path: "/donation-campaign/pay/:donationCampaignId",
		component: <PayDonationCampaignComponent />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.SLIDER.DISPLAY,
		displayType: displayHeader.SLIDER.TYPE.DONATION,
	},
	{
		title: "Add Meetings",
		path: "/meeting/add",
		component: <AddMeetings />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.MEETINGS,
	},
	{
		title: "Edit Meetings",
		path: "/meetings/:id/edit",
		component: <EditMeetings />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.MEETINGS,
	},
	{
		title: "Edit Meetings",
		path: "/meetings/:id/remark",
		component: <MeetingRemark />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.MEETINGS,
	},
	{
		title: "Group Meetings",
		path: "/meetings",
		component: <MeetingsList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.MEETINGS,
	},
	{
		title: "Group Meeting Details",
		path: "/meetings/:id/details",
		component: <MeetingDetails />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.MEETINGS,
	},
	{
		title: "Meetings Settings",
		path: "/meetings/meetings-settings",
		component: <MeetingsSettings />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.MEETINGS,
	},
	{
		title: "Attendance",
		path: "/meetings/:id/attendance",
		component: <Attendance />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.MEETINGS,
	},
	{
		title: "Passbook",
		path: "/passbook",
		component: <Passbook />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.PASSBOOK,
	},
	{
		title: "Member Passbook",
		path: "/member-passbook",
		component: <MemberPassbook />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.PASSBOOK,
	},
	{
		title: "Member Passbook Details",
		path: "/member-passbook/:id/details",
		component: <MemberPassbookDetails />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.PASSBOOK,
	},
	{
		title: "Election",
		path: "/election",
		component: <Election />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ELECTION,
	},
	{
		title: "Add Election",
		path: "/election/add",
		component: <AddElection />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ELECTION,
	},
	{
		title: "Election Details",
		path: "/election/:id/details",
		component: <ElectionDetails />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ELECTION,
	},
	{
		title: "Election Add Candidate",
		path: "/election/:id/add-candidate",
		component: <ElectionAddCandidates />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ELECTION,
	},
	{
		title: "Election Referee Result",
		path: "/election/:id/voting-result",
		component: <ElectionRefereeResult />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ELECTION,
	},
	{
		title: "Election Result Feedback",
		path: "/election/:id/feedback",
		component: <ElectionRefereeResultFeedback />,
		settings: ["Header", "Footer"],
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ELECTION,
	},
	{
		title: "Vote",
		path: "/election/:id/vote",
		component: <Vote />,
		settings: ["Header", "Footer"],
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ELECTION,
	},
	{
		title: "Vote Terms Conditions",
		path: "/vote-terms-conditions",
		component: <VoteTermsConditions />,
		settings: ["Header", "Footer"],
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ELECTION,
	},
	{
		title: "Vote Thank You",
		path: "/election/:id/vote-thank-you",
		component: <VoteThankYou />,
		settings: ["Header", "Footer"],
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ELECTION,
	},
	{
		title: "Election Result",
		path: "/election/:id/result",
		component: <ElectionResult />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ELECTION,
	},
	{
		title: "News",
		path: "/news",
		component: <News />,
		settings: ["Header", "Footer"],
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.NEWS,
	},
	{
		title: "News Detail",
		path: "/news/:id/detail",
		component: <NewsDetail />,
		settings: ["Header", "Footer"],
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.NEWS,
	},
	{
		title: "News Detail",
		path: "/mobile-news-view/:id/detail",
		component: <NewsDetailMobile />
	},
	{
		title: "About Us",
		path: "/about-us",
		component: <AboutUs />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.ABOUT_US,
	},
	{
		title: "Terms & Conditions",
		path: "/terms-conditions",
		component: <TermsConditions />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.TERMS_AND_CONDITION,
	},

	{
		title: "Privacy Policy",
		path: "/privacy-policy",
		component: <PrivacyPolicy />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.PRIVACY_POLICY,
	},
	{
		title: "All Reports",
		path: "/all-reports",
		component: <AllReports />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.REPORTS,
	},
	{
		title: "Feedback",
		path: "/feedback",
		component: <Feedback />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.FEEDBACK,

	},
	{
		title: "ContactUs",
		path: "/contact-us",
		component: <ContactUs />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.CONTACT_US,
	},
	{
		title: "Notification List",
		path: "/notification",
		component: <NotificationList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.NOTIFICATION,
	},
	{
		title: "Notification Redirect",
		path: "/notification/:id",
		component: <NotificationRedirect />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.NOTIFICATION,
	},
	// {
	// 	title: "Cash Entries",
	// 	path: "/cash-entries",
	// 	component: <CashEntries />,
	// 	settings: ["Header", "Footer"],
	// 	auth: true,
	// 	displayCategory: displayHeader.BANNER.DISPLAY,
	// 	displayType: displayHeader.BANNER.TYPE.WALLET,
	// },
	// {
	// 	title: "Add Cash",
	// 	path: "/cash-entries/add-cash",
	// 	component: <AddCash />,
	// 	settings: ["Header", "Footer"],
	// 	auth: true,
	// 	displayCategory: displayHeader.BANNER.DISPLAY,
	// 	displayType: displayHeader.BANNER.TYPE.WALLET,
	// },
	{
		title: "Associated Groups",
		path: "/associated-groups",
		component: <AssociatedGroups />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.SAVING_GROUP,
	},
	{
		title: "Welfare Request",
		path: "/welfare-request",
		component: <WelfareRequestList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.WELFARE_REQUEST,
	},
	{
		title: "Add Welfare Request",
		path: "/welfare-request/add",
		component: <AddWelfareRequest />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.WELFARE_REQUEST,
	},
	{
		title: "My Welfare Request",
		path: "/my-welfare-request",
		component: <MyWelfareRequestList />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.WELFARE_REQUEST,
	},
	{
		title: "Add My Welfare Request",
		path: "/my-welfare-request/add",
		component: <AddMyWelfareRequest />,
		settings: ["Header", "Footer"],
		auth: true,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.WELFARE_REQUEST,
	},
	{
		title: "Generated OTP",
		path: "/generated-otp",
		component: <GeneratedOtp />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.GENERATED_OTP,
	},
	{
		title: "Transfer Funds",
		path: "/transfer-funds",
		component: <TransferFundsList />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.TRANSFER_FUND,
	},
	{
		title: "Add Transfer Funds",
		path: "/add-transfer-funds",
		component: <AddTransferFunds />,
		settings: ["Header", "Footer"],
		auth: false,
		displayCategory: displayHeader.BANNER.DISPLAY,
		displayType: displayHeader.BANNER.TYPE.TRANSFER_FUND,
	},
	{
		title: "Unauthorized",
		path: "/unauthorized",
		component: <Unauthorized />,
		settings: ["Header", "Footer"],
		displayCategory: 'banner',
	},
	{
		title: "Page Not Found",
		path: "/",
		component: <PageNotFound />,
		settings: ["Header", "Footer"],
		exact: false,
		displayCategory: 'banner',
	},
];

const AppRouter: React.FC = () => {
	return (
		<Switch>
			{RouterConfig.map(({ exact, path, component, settings, auth, displayCategory, displayType }, index) => {
				return (
					<Route key={"routeLayout"+index} exact={exact !== false} path={path}>
						<LayoutView
							component={component}
							settings={settings}
							isAuthenticationRequired={auth}
							displayCategory={displayCategory}
							displayType={displayType}
						/>
					</Route>
				);
			})}
		</Switch>
	);
};

export default AppRouter;
